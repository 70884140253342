<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    id="round-trip"
    class="w-100"
  >
    <div class="section-airport w-100 d-flex flex-wrap flex-lg-nowrap align-items-center mb-1 mb-lg-2">
      <!-- ANCHOR From -->
      <b-card
        class="flex-grow-1 mb-0 w-100 mb-1 mb-lg-0"
        body-class="p-50"
      >
        <div class="ml-1 text-subtitle">
          {{ $t('flight.startFrom') }}
        </div>
        <SearchAirportSelect :data-prop.sync="searchData.flights[0].startPoint" />
      </b-card>

      <!-- ANCHOR switch -->
      <div class="switch_airport mx-auto mx-lg-2 my-lg-1 flex-shrink-1">
        <b-button
          :variant="['lg', 'xl'].includes(appBreakPoint) ? 'white' : 'info'"
          class="btn-icon rounded-circle shadow-lg text-white rounded-circle"
          :disabled="
            !(
              searchData.flights[0].startPoint && searchData.flights[0].endPoint
            )
          "
          @click="$emit('swap-from-to')"
        >
          <IconSvg
            :src="require('@icons/swap.svg')"
            class="m-25 d-flex-center"
            :class="
              ['lg', 'xl'].includes(appBreakPoint) ? 'text-body' : 'text-white'
            "
            size="20px"
          />
        </b-button>
      </div>

      <!-- ANCHOR TO -->
      <b-card
        class="flex-grow-1 mb-0 w-100"
        body-class="p-50"
      >
        <div class="ml-1 text-subtitle">
          {{ $t('flight.endAt') }}
        </div>
        <SearchAirportSelect
          :data-prop.sync="searchData.flights[0].endPoint"
          right
        />
      </b-card>
    </div>

    <b-card
      class="mb-1 mb-lg-2"
      no-body
    >
      <div class="d-flex flex-wrap flex-lg-nowrap justify-content-center m-50">
        <!-- ANCHOR Depart Date -->
        <b-col
          cols="12"
          lg="5"
          class="d-flex justify-content-center align-items-center mb-75 mb-lg-0"
        >
          <SearchDatePicker
            :date.sync="searchData.flights[0].departDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100"
            style="max-width: 250px"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #icon>
              <IconSvg
                :src="require('@icons/airplane-up.svg')"
                class="mx-50 d-flex-center"
                size="30px"
              />
            </template>
          </SearchDatePicker>
        </b-col>

        <!-- ANCHOR Return Date -->
        <b-col
          cols="12"
          lg="5"
          class="d-flex justify-content-center align-items-center"
        >
          <SearchDatePicker
            :date.sync="searchData.flights[0].returnDate"
            :config="{
              minDate: searchData.flights[0].departDate,
            }"
            class="w-100"
            style="max-width: 250px"
          >
            <template #icon>
              <IconSvg
                :src="require('@icons/airplane-down.svg')"
                class="mx-50 d-flex-center"
                size="34px"
              />
            </template>
          </SearchDatePicker>
        </b-col>
      </div>
    </b-card>

    <b-card
      class="mb-1 mb-lg-2"
      no-body
    >
      <div class="d-flex flex-column flex-md-row gap-1 w-100 px-1 py-50 ml-2 ml-md-0 justify-content-md-center">
        <!-- ANCHOR Class Booking -->
        <SearchClassBookingSelect
          v-if="bookingData && bookingData.source.includes('VN1A') && isAddFlights"
          :is-search-monthly-cheapest-fare="searchData.isSearchMonthlyCheapestFare"
          :is-disabled="bookingData.source.includes('VN1A')"
          :is-search-class-booking.sync="searchData.isSearchClassBooking"
          class="d-flex justify-content-center justify-content-md-start"
        />
        <!-- ANCHOR Direct flight select -->
        <SearchDirectFlightSelect
          :number-of-stop.sync="searchData.numberOfStop"
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="ml-1"
        />

        <SearchSplitTripFareSwitch class="ml-1" />
      </div>
    </b-card>

    <!-- Show cho đẹp => HẾT -->
    <b-card
      v-if="['lg', 'xl'].includes(appBreakPoint)"
      class="mb-1 mb-lg-2"
      no-body
    >
      <div class="d-flex flex-wrap flex-lg-nowrap gap-1 w-100 px-1 py-50 d-flex-center mx-1">
        <!-- ANCHOR Airlines -->
        <SearchSourceSelect
          style="min-width: 240px"
          :airlines.sync="searchData.airlines"
          :is-search-monthly-cheapest-fare="
            searchData.isSearchMonthlyCheapestFare
          "
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="divider-after flex-fill w-100"
        />

        <!-- ANCHOR Passenger -->
        <SearchPassenger
          :adult.sync="searchData.adult"
          :child.sync="searchData.child"
          :infant.sync="searchData.infant"
          :is-search-monthly-cheapest-fare="
            searchData.isSearchMonthlyCheapestFare
          "
          :is-search-class-booking="searchData.isSearchClassBooking"
          class="flex-fill w-100"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton, BCol } from 'bootstrap-vue'
import moment from 'moment'

export default {
  components: {
    BCard,
    BButton,
    BCol,

    SearchAirportSelect: () => import('./components/SearchAirportSelect.vue'),
    SearchSourceSelect: () => import('./components/SearchSourceSelect.vue'),
    SearchPassenger: () => import('./components/SearchPassenger.vue'),
    SearchDatePicker: () => import('./components/SearchDatePicker.vue'),
    SearchDirectFlightSelect: () => import('./components/SearchDirectFlightSelect.vue'),
    SearchClassBookingSelect: () => import('./components/SearchClassBookingSelect.vue'),
    SearchSplitTripFareSwitch: () => import('./components/SearchSplitTripFareSwitch.vue'),
  },
  props: {
    searchData: {
      type: Object,
      default: () => {},
    },
    bookingData: {
      type: Object,
      default: () => {},
    },
    isAddFlights: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    function handleDepartDate(val) {
      const departDate = new Date(val)
      const returnDate = new Date(this.searchData.flights[0].returnDate)
      if (departDate > returnDate) {
        // eslint-disable-next-line vue/no-mutating-props
        this.searchData.flights[0].returnDate = moment(
          new Date(
            Date.UTC(
              departDate.getFullYear(),
              departDate.getMonth(),
              departDate.getDate() + 3,
            ),
          ),
        ).format('YYYY-MM-DD')
      }
    }
    return {
      handleDepartDate,
    }
  },
}
</script>

<style lang="scss" scoped>
#round-trip ::v-deep {
  .divider-after {
    &::after {
      content: '';
      display: inline-block;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #d3d3d3;
      height: 28px;
      margin: 0 1rem;

      @media (max-width: 768px) {
        content: none;
      }
    }
  }
}

.section-airport {
  position: relative;
  .switch_airport {
    @media (max-width: 991px) {
      position: absolute;
      z-index: 99;
      right: -40px;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
